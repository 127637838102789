import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import SvgIcon from '@mui/material/SvgIcon';
import { grey } from '@mui/material/colors';
import { convertDateToFrench } from '../common/Frame';
import upsLogo from '../static/images/logo_UPS.png';
import tntLogo from '../static/images/logo_TNT.png';
import fedexLogo from '../static/images/logo_FedEx.png';
import springLogo from '../static/images/logo_SPRING.png';
import aramexLogo from '../static/images/logo_ARAMEX.png';
import { getShipmentTransportDashboard, setShipmentTransportDashLoad } from '../actions';
import { numberThousands } from '../common/Frame';
import useToken from '../login/useToken';
import PopUpInfo from '../shipments/PopUpInfo';
import PopUpPackage from '../shipments/PopUpPackage';
import AdditionalAddressInfo from '../shipments/AdditionalAddressInfo';
import { grossMargin } from '../shipments/ShipmentsBody';


const useStyles = makeStyles()((theme) => { return {
  root: {
    background: '#F7F7F7',
  },
  tableHead:{
    background: '#727272',

    '& th': {
      textAlign: 'center',
      color: '#fff',
      fontWeight: '700',
    },
  },
  differentRows: {
    overflowWrap: 'anywhere',
    '& td': {
      textAlign: 'center',
    },
    '& .none': {
      backgroundColor: 'inherit',
    },
    '& .no_quotes': {
      background: '#ff8282',
    },
    '& .ok': {
      background: '#c5fac5',
    },
    '& .tracked_by_recipient': {
      background: '#ffe56b',
    },
    '& .discounted': {
      background: '#c6d5a3',
    },
    '& .pickup_error': {
      background: '#87dcea',
    },
    '& .payment_not_done': {
      background: '#e3b7e3',
    },
    '& .quote_selected': {
      background: '#beb9b9',
    },
    '& .greenIcon': {
      color: 'green',
    },
    '& .checkCircleIcon': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  infoShipmentColumn: {
    textAlign: 'left !important',
  },
  transportLoading: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '2.5rem',
    color: '#9e9b9b',
  },
  transportEmpty: {
    textAlign: 'start !important',
    fontStyle: 'italic',
    fontSize: '2.5rem',
    color: '#9e9b9b',
  },
  borderBottomRow: {
    '& td': {
      paddingBottom: theme.spacing(0),
      borderBottom: 'none',
    },
  },
  tableRowAdditional: {
    '& td': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(.25),
      borderBottom: 'groove',
    },
  },
  packageIcon: {
    transform: 'scaleX(-1)', // flip horizontal
  },
  displayNone: {
      display: 'none',
  },
  tableCellUsername: {
      borderTop: 'none',
  },
  username: {
      textAlign: 'left',
      '& a': {
          color: '#000',
          textDecoration: 'none',
      },
  },
  carrierLogo: {
      maxWidth: '80%',
  },
  carrierLogoTransportInfo: {
      maxHeight: '24px',
  },
  doneAllIconGreen: {
    color: 'green',
  },
  doneAllIconGrey: {
    color: grey[700],
  },
  doneAllIconBlack: {
    color: grey[900],
  },
  arrowDropIconButton: {
      padding: '4px 12px',
      '& .MuiSvgIcon-root': {
          fontSize: '2rem',
      },
  },
  transportInfo: {
      "& .MuiTableCell-root": {
        borderBottom: "1px solid #a9a1a1",
      },
      backgroundColor: '#cbefd5',
  },
  transportInfoHead: {
      '& th': {
          textAlign: 'center',
      },
      '& .floatLeft': {
          textAlign: 'start',
      },
  },
  transportInfoBody: {
      '& td': {
          textAlign: 'center',
          // borderBottom: 'none',
      },
      '& .floatLeft': {
          textAlign: 'start',
      },
  },
  error: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '1.5%',
  },
  gridCustomerAccount: {
      display: 'flex',
      padding: theme.spacing(1),
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
  },
  hrefCustomerAccount: {
      color: '#616161',
      padding: theme.spacing(.5),
      '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          borderRadius: '50%',
      },
  },
  noUser: {
      padding: theme.spacing(.5),
  },
  noWrap: {
      whiteSpace: 'nowrap',
  },
  iconAtSameLine: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentMethod: {
    padding: theme.spacing(0.5),
  },
  bugerIconGrid: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}});

function InvoiceIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M461 1679 c-83 -42 -127 -135 -138 -291 l-6 -88 92 0 91 0 0 -466 c0
        -412 2 -470 16 -501 32 -66 7 -64 582 -61 l520 3 39 31 c55 45 92 123 100 212
        l6 72 -91 0 -91 0 -3 498 c-3 476 -4 498 -23 531 -11 19 -33 43 -48 55 -28 21
        -38 21 -515 24 -482 2 -487 2 -531 -19z m995 -77 c32 -21 34 -52 34 -531 l0
        -481 -404 0 -403 0 -6 -80 c-6 -89 -30 -145 -63 -145 -19 0 -19 14 -24 600 -4
        463 -8 605 -18 623 l-13 22 443 0 c244 0 448 -4 454 -8z m-956 -144 l0 -68
        -45 0 c-36 0 -45 3 -45 18 0 33 27 120 45 149 l19 28 12 -30 c7 -17 13 -60 14
        -97z m1160 -978 c0 -30 -26 -75 -56 -99 -26 -21 -36 -21 -446 -21 -396 0 -420
        1 -413 18 5 14 25 107 25 118 0 2 200 4 445 4 442 0 445 0 445 -20z"/>
        <path d="M820 1442 c0 -22 -7 -32 -34 -45 -26 -12 -38 -26 -47 -56 -20 -66 -1
        -94 87 -136 41 -20 74 -40 74 -45 0 -22 -48 -29 -100 -16 l-51 13 -15 -35
        c-16 -39 -11 -47 36 -58 47 -11 50 -13 50 -44 0 -30 1 -31 43 -28 39 3 42 5
        45 33 2 23 11 34 34 44 36 16 62 70 53 111 -8 37 -49 73 -113 100 -70 30 -62
        45 21 44 l65 -2 7 35 c7 39 -4 53 -42 53 -19 0 -23 6 -23 30 0 29 -2 30 -45
        30 -43 0 -45 -1 -45 -28z"/>
        <path d="M1117 1413 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
        0 c-74 0 -137 -3 -140 -7z"/>
        <path d="M1112 1178 l3 -43 138 -3 137 -3 0 46 0 45 -141 0 -140 0 3 -42z"/>
        <path d="M1117 1023 c-4 -3 -7 -24 -7 -45 l0 -38 140 0 140 0 0 45 0 45 -133
        0 c-74 0 -137 -3 -140 -7z"/>
        <path d="M732 778 l3 -43 330 0 330 0 3 43 3 42 -336 0 -336 0 3 -42z"/>
      </g>
    </SvgIcon>
  );
}

function CreditCardIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M1343 1730 c-447 -156 -1252 -456 -1270 -473 -49 -44 -45 -60 97
      -455 73 -202 137 -371 142 -377 4 -5 8 183 8 418 0 453 1 462 49 494 24 17 76
      18 667 23 l641 5 -64 175 c-35 96 -72 183 -81 193 -39 38 -73 38 -189 -3z"/>
      <path d="M397 1299 c-15 -11 -31 -37 -37 -57 -8 -25 -10 -186 -8 -490 l3 -454
      32 -28 c20 -18 48 -31 75 -35 24 -3 354 -5 733 -3 640 3 692 4 716 21 14 9 31
      29 37 43 9 18 12 160 12 490 l0 466 -34 34 -34 34 -735 0 c-726 0 -735 0 -760
      -21z m1491 -51 c7 -7 12 -34 12 -60 l0 -48 -745 0 -745 0 0 41 c0 22 5 49 10
      60 10 19 29 19 733 19 549 0 726 -3 735 -12z m12 -637 c0 -288 0 -290 -22
      -305 -20 -14 -105 -16 -723 -16 -618 0 -703 2 -723 16 -22 15 -22 17 -22 305
      l0 289 745 0 745 0 0 -289z"/>
      </g>
    </SvgIcon>
  );
}

const errorConfig = {
  color: 'red',
  messageLength: 40,
  header: 'Erreur de description'
};

const additionalPickUpConfig = {
  color: '#3a3c47', // 0008ff
  messageLength: 30,
  header: 'Additional Pickup Info'
};

const ShipmentDisplay = (props) => {
  const { classes } = useStyles();
  const { token } = useToken();
  const scrollToTransport = useRef(null);
  const { shipment,
    dashboardShipmentTransport,
    shipmentTransportDashLoad,
    getShipmentTransportDashboard,
    setShipmentTransportDashLoad
  } = props;
  const [open, setOpen] = useState(false);

  const transportLoading = (<TableRow>
    <TableCell colSpan={8} className={classes.transportLoading}>Loading...</TableCell>
  </TableRow>);

  const transportEmpty = (<TableRow>
    <TableCell colSpan={8} className={classes.transportEmpty}>(Empty)</TableCell>
  </TableRow>);
  
  let transporter_amount = null;
  let shipment_amount = null;
  let insurance_amount = null;
  let vat_amount = null;
  let total_amount = null;
  
  if(shipment.ikompar_invoice) {
    if(shipment.ikompar_invoice.transporter_amount) {
      transporter_amount = Number.parseFloat(shipment.ikompar_invoice.transporter_amount).toFixed(2);
    }
    if(shipment.ikompar_invoice.shipment_amount) {
      shipment_amount = Number.parseFloat(shipment.ikompar_invoice.shipment_amount).toFixed(2);
    }
    if(shipment.ikompar_invoice.insurance_amount) {
      insurance_amount = Number.parseFloat(shipment.ikompar_invoice.insurance_amount).toFixed(2);
    }
    if(shipment.ikompar_invoice.vat_amount) {
      vat_amount = Number.parseFloat(shipment.ikompar_invoice.vat_amount).toFixed(2);
    }
    if(shipment.ikompar_invoice.total_amount) {
      total_amount = Number.parseFloat(shipment.ikompar_invoice.total_amount).toFixed(2);
    }
  }
  
  const paymentMethodM = ((transporter_amount && shipment_amount) ? Number.parseFloat((1 - transporter_amount / shipment_amount) * 100).toFixed(2) : null);

  const paymentMethodAndInvoice = (!transporter_amount && !shipment_amount && !insurance_amount && !vat_amount && !total_amount) ? null : (
    <Grid item xs={12} className="paymentMethod">
      <span className={classes.iconAtSameLine}>
        {shipment.ikompar_invoice !== null && parseFloat(shipment.ikompar_invoice.number.split("-").join(''))}&nbsp;
        <Tooltip
          className={classes.paymentMethod}
          title={<div>
            Transporter CHF: {transporter_amount !== 'NaN' && transporter_amount}
            <br />Shipment CHF: {shipment_amount !== 'NaN' && shipment_amount}
            <br />GM (%): {paymentMethodM !== 'Infinity' && paymentMethodM}
            <br />Insurance CHF: {insurance_amount !== 'NaN' && insurance_amount}
            <br />Vat CHF: {vat_amount !== 'NaN' && vat_amount}
            <br />Total CHF: {total_amount !== 'NaN' && total_amount}
            </div>}
          placement="left-end"
        >
          <IconButton size="large">
            {(shipment.ikompar_invoice === null) ? null : (shipment.ikompar_invoice.payment_method === 'CreditCard') ? <CreditCardIcon /> : (shipment.ikompar_invoice.payment_method === 'Invoice') ? <InvoiceIcon /> : null}
          </IconButton>
        </Tooltip>
      </span>
    </Grid>
  );

  function discount() {
    if(shipment.ikompar_invoice) {
      if(shipment.ikompar_invoice.discount_amount !== '0.00') {
        return <span>DISCOUNT: {shipment.ikompar_invoice.discount_amount}</span>;
      }
    }
  }

  function scrollToShowTransport() {
    setTimeout(() => {
      scrollToTransport.current.scrollIntoView({ behavior: 'smooth', block: "start" });      
    }, 1000);
  }

  return (
    <React.Fragment>
      <TableRow className={`${classes.borderBottomRow} ${shipment.admin_status}`}>
          <TableCell className={classes.infoShipmentColumn}>
              ID:{shipment.id}<br />
              N suivi:<br />
              <span>{shipment.ikompar_invoice && shipment.ikompar_invoice.airwaybill.replace(/<br\/>/g, "\n")}</span><br />
              Enlèvement:<br />
              {shipment.pickup_request.confirmation_number && shipment.pickup_request.confirmation_number}
          </TableCell>
          <TableCell>
              {shipment.creation && convertDateToFrench(shipment.creation)}<br />
              {shipment.creation && `(${shipment.creation.slice(11, 16)})`}
          </TableCell>
          <TableCell>
              {shipment.pickup_date && convertDateToFrench(shipment.pickup_date)}<br />

              {(shipment.pickup_request.ready_time && shipment.pickup_request.close_time) ? (`${shipment.pickup_request.ready_time && shipment.pickup_request.ready_time.slice(0, 5)} - ${shipment.pickup_request.close_time && shipment.pickup_request.close_time.slice(0, 5)}`) : null}
          </TableCell>
          <TableCell>
              <span><b>{shipment.pickup_contact && shipment.pickup_contact}</b></span><br />
              <span className={classes.contactNames}><b><i>{shipment.pickup_contact_name && shipment.pickup_contact_name}</i></b></span><br />
              {shipment.pickup_address && shipment.pickup_address}<br />
              {shipment.pickup_country_name && shipment.pickup_country_name}<br />
              {(shipment.pickup_request.additional_info || shipment.pickup_request.floor_number || shipment.pickup_request.security_code || shipment.shipment_customer_reference) ? <AdditionalAddressInfo shipment={shipment} config={additionalPickUpConfig} /> : null}
              {shipment.pickup_phone && shipment.pickup_phone}<br />
              <span className={shipment.pickup_email && (shipment.pickup_email.length < 34) && classes.noWrap}>{shipment.pickup_email && shipment.pickup_email}</span><br />
          </TableCell>
          <TableCell>
              <span><b>{shipment.delivery_contact && shipment.delivery_contact}</b></span><br />
              <span className={classes.contactNames}><b><i>{shipment.delivery_contact_name && shipment.delivery_contact_name}</i></b></span><br />
              {shipment.delivery_address && shipment.delivery_address}<br />
              {shipment.delivery_country_name && shipment.delivery_country_name}<br />
              {shipment.delivery_phone && shipment.delivery_phone}<br />
              <span className={shipment.delivery_email && (shipment.delivery_email.length < 34) && classes.noWrap}>{shipment.delivery_email && shipment.delivery_email}</span><br />
          </TableCell>
          <TableCell>
              <PopUpPackage shipment={shipment} />

              {shipment.package_group && shipment.package_group.packages[0] && <span>{Math.trunc(shipment.package_group.packages[0].length)}x{Math.trunc(shipment.package_group.packages[0].width)}x{Math.trunc(shipment.package_group.packages[0].height)}cm<br /></span>}

              {shipment.package_group && `${shipment.package_group.total_weight}kg`}
          </TableCell>
          <TableCell>
              <span className={(shipment.quote_transporter === undefined) ? classes.displayNone : ''}>
                  <img
                      className={classes.carrierLogo}
                      src={
                          shipment.quote_transporter === 'UPS' ? upsLogo
                          : shipment.quote_transporter === 'FedEx' ? fedexLogo
                          : (shipment.quote_transporter === 'TNT' || shipment.quote_transporter === 'SPRINGTNT') ? tntLogo
                          : shipment.quote_transporter === 'SPRING' ? springLogo
                          : shipment.quote_transporter === 'ARAMEX' ? aramexLogo
                          : ''
                      }
                      alt={`${shipment.quote_transporter} Logo`}
                  />
              </span>
              {shipment.is_ddp && <span><br />DDP<br /></span>}
              <span className={classes.error}>
                  {shipment.pickup_request.error_message && <PopUpInfo message={shipment.pickup_request.error_message} config={errorConfig} />}
              </span>
              {shipment.coupon && <span>COUPON: {shipment.coupon}<br /></span>}
              {discount()}
          </TableCell>
          <TableCell>
              <Grid container>
                  {paymentMethodAndInvoice}
                  <Grid item xs={12} className="checkCircleIcon">
                      <DoneAllIcon
                          fontSize="large"
                          className={shipment.has_pickup ? classes.doneAllIconGreen
                          : (shipment.admin_status === 'quote_selected') ? classes.doneAllIconBlack
                          : classes.doneAllIconGrey}
                      />
                  </Grid>
                  <Grid item xs={6} className={classes.gridCustomerAccount}>
                  </Grid>
                  <Grid item xs={6} className={classes.bugerIconGrid}>
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
                  <Grid item xs={12}>
                    {(shipment.admin_status === "ok") ? "RESERVATION OK"
                    : (shipment.admin_status === "tracked_by_recipient") ? "FINALISATION ENVOI"
                    : (shipment.admin_status === "none") ? "SHIPMENT CREE"
                    : (shipment.admin_status === "quote_selected") ? "OFFRE CHOISIE"
                    : (shipment.admin_status === "payment_not_done") ? "PAIEMENT"
                    : (shipment.admin_status === "no_quotes") ? "PAS D’OFFRES"
                    : (shipment.admin_status === "discounted") ? "DISCOUNTED"
                    : (shipment.admin_status === "pickup_error") ? "PICKUP ERROR"
                    : ""}
                  </Grid>
              </Grid>
          </TableCell>
      </TableRow>
      <TableRow className={`${classes.tableRowAdditional} ${shipment.admin_status}`}>
        <TableCell colSpan={8} >
          <Grid container>
            <Grid item xs={5} className={classes.username}><b>{shipment.user}</b></Grid>
            <Grid item xs={2}>
              <IconButton
                title="Carriers"
                aria-label="expand row"
                className={classes.arrowDropIconButton}
                ref={scrollToTransport}
                onClick={() => {
                  if (!open) {
                    getShipmentTransportDashboard(shipment.id, token);
                    setShipmentTransportDashLoad(true);
                    scrollToShowTransport();
                  }

                  setOpen(!open);
                }}
                size="large">
                {open ? <ExpandLessIcon color="inherit" className={classes.arrowDropIcon} /> : <ExpandMoreIcon color="inherit" className={classes.arrowDropIcon} />}
              </IconButton>
            </Grid>
            <Grid item xs={5} />
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow className={classes.transportInfo}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <colgroup>
                <col span="1" style={{width: "13%"}} />
                <col span="1" style={{width: "13%"}} />
                <col span="1" style={{width: "11%"}} />
                <col span="1" style={{width: "11%"}} />
                <col span="1" style={{width: "11%"}} />
                <col span="1" style={{width: "15%"}} />
                <col span="1" style={{width: "26%"}} />
                </colgroup>
                <TableHead>
                  <TableRow
                    className={classes.transportInfoHead}
                    // ref={scrollToTransport}
                  >
                    <TableCell className="floatLeft"><b>Carrier</b></TableCell>
                    <TableCell><b>Delivery Date</b></TableCell>
                    <TableCell><b>Delivered Time</b></TableCell>
                    <TableCell><b>Our Rate</b></TableCell>
                    <TableCell><b>Gross Margin</b></TableCell>
                    <TableCell><b>IKompar Nego Rate</b></TableCell>
                    <TableCell className="floatLeft"><b>Service</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.transportInfoBody}>
                  {shipmentTransportDashLoad ? transportLoading : null}
                  {!dashboardShipmentTransport.length ? transportEmpty : dashboardShipmentTransport.map((item, i) => {
                    return (<React.Fragment key={i}>
                      <TableRow>
                        <TableCell className="floatLeft">
                          <span className={(item.transporter_name === undefined) ? classes.displayNone : ''}>
                            <img
                              className={classes.carrierLogoTransportInfo}
                              src={
                                item.transporter_name === 'UPS' ? upsLogo
                                : item.transporter_name === 'FedEx' ? fedexLogo
                                : (item.transporter_name === 'TNT' || item.transporter_name === 'SPRINGTNT') ? tntLogo
                                : item.transporter_name === 'SPRING' ? springLogo
                                : item.transporter_name === 'ARAMEX' ? aramexLogo
                                : ''
                              }
                              alt={`${item.transporter_name} Logo`}
                            />
                          </span>
                        </TableCell>
                        <TableCell>{item.delivery_date}</TableCell>
                        <TableCell>{item.delivery_time}</TableCell>
                        <TableCell>{item.ikompar_quote.length ? numberThousands(item.ikompar_quote[0].amount) : ''}</TableCell>
                        <TableCell>{item.ikompar_quote.length ? grossMargin(numberThousands(item.ikompar_quote[0].amount), numberThousands(item.transporter_quote[0].amount_ikompar)) : ''}</TableCell>
                        <TableCell>{numberThousands(item.transporter_quote[0].amount_ikompar)}</TableCell>
                        <TableCell className="floatLeft">{item.service_name}</TableCell>
                      </TableRow>
                    </React.Fragment>);
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    dashboardShipmentTransport: state.dashboardShipmentTransport,
    shipmentTransportDashLoad: state.shipmentTransportDashLoad
  };
};

export default connect(mapStateToProps, { getShipmentTransportDashboard, setShipmentTransportDashLoad })(ShipmentDisplay);
